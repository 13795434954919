@import "./assets/styles/_mixins";

:root,
*::before,
*::after {
  // https://fonts.google.com/noto/specimen/Noto+Sans
  --font-family-sans-serif: "Noto Sans", sans-serif;
  // https://fonts.google.com/noto/specimen/Noto+Sans+Mono
  --font-family-monospace: "Noto Sans Mono", monospace;
  --font-size-base: 16px;

  --color-white: hsl(0, 0%, 100%);
  --color-gray: hsl(0, 0%, 50%);
  --color-black: hsl(0, 0%, 0%);

  --color-zelda-1-triforce-orange: #ea9e22;
  --color-zelda-1-triforce-blue: #9290ff;

  --half-content-width: 400px;

  --text-icon-width: 1rem;
  --text-icon-height: 1rem;

  --overworld-map-width-in-pixels: 4096;
  --overworld-map-height-in-pixels: 1408;
}

* {
  box-sizing: border-box;
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;
}

body {
  align-items: stretch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-black);
  color: var(--color-white);
  font-family: var(--font-family-sans-serif);
  font-size: var(--font-size-base);
  margin: 0;
  position: relative;
}

button {
  background-color: transparent;
  border-width: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
}

code {
  font-family: var(--font-family-monospace);
  font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
}

html {
  height: -webkit-fill-available;
}

img {
  display: block;
}

input,
select {
  font-family: var(--font-family-sans-serif);
  font-size: var(--font-size-base);
}

p {
  margin: 0;
}
