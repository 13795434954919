@import "../../assets/styles/_mixins";

.HomePage {
  align-items: center;
  display: grid;
  grid: "nav" min-content "content" auto "footer" min-content;
  min-height: 100vh;

  &-Nav {
    grid-area: nav;

    @include with-breakpoint("tablet-portrait-up") {
      padding-left: max(0rem, calc(50% - var(--half-content-width)));
      padding-right: max(0rem, calc(50% - var(--half-content-width)));
    }
  }

  &-Content {
    align-items: center;
    display: flex;
    flex-flow: column;
    grid-area: content;
    margin-bottom: 2rem;
    margin-top: 1rem;
    padding-left: max(1rem, calc(50% - var(--half-content-width)));
    padding-right: max(1rem, calc(50% - var(--half-content-width)));
    text-align: center;

    @include with-breakpoint("phone-only") {
      gap: 1.2rem;
    }

    @include with-breakpoint("tablet-portrait-up") {
      gap: 1rem;
    }
  }

  &-LogoContainer {
    display: inline-block;
    position: relative;
  }

  &-Logo {
    image-rendering: pixelated;

    @include with-breakpoint("phone-only") {
      height: 64px;
      width: 64px;
    }

    @include with-breakpoint("tablet-portrait-up") {
      height: 128px;
      width: 128px;
    }
  }

  &-AlphaPreview {
    animation-duration: 2s;
    animation-name: alpha-preview;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    color: var(--color-zelda-1-triforce-blue);
    font-weight: bold;
    position: absolute;

    @include with-breakpoint("phone-only") {
      font-size: 0.8em;
      top: 25%;
      right: -100%;
    }

    @include with-breakpoint("tablet-portrait-up") {
      font-size: 1em;
      top: 35%;
      right: -60%;
    }
  }

  &-Title {
    color: var(--color-zelda-1-triforce-orange);

    @include with-breakpoint("phone-only") {
      font-size: 2.5em;
    }

    @include with-breakpoint("tablet-portrait-up") {
      font-size: 4em;
    }
  }

  &-Description {
    display: flex;
    flex-wrap: wrap;
    font-weight: 300;
    justify-content: center;

    @include with-breakpoint("phone-only") {
      font-size: 1.2em;

      &_singleLine {
        display: none;
      }

      &_multiLine {
        display: initial;
      }
    }

    @include with-breakpoint("tablet-portrait-up") {
      font-size: 2em;

      &_singleLine {
        display: initial;
      }

      &_multiLine {
        display: none;
      }
    }
  }

  &-Author {
    color: var(--color-gray);
    font-size: 0.8em;
  }

  &-TrackButton {
    align-items: center;
    display: inline-flex;
    flex-flow: column;
    font-size: 1.5em;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    position: relative;

    @include with-breakpoint("phone-only") {
      margin-top: 0.5rem;
    }

    @include with-breakpoint("tablet-portrait-up") {
      margin-top: 1rem;
      transition: all 150ms ease-in-out;

      &::after {
        box-shadow: 0 0 30px 0 var(--color-zelda-1-triforce-orange);
        content: "";
        height: 100%;
        left: 0;
        opacity: 0;
        position: absolute;
        top: 0;
        transition: opacity 150ms ease-in-out;
        width: 100%;
      }

      &:hover {
        transform: scale(1.1);
      }

      &:hover::after {
        opacity: 0.5;
      }
    }
  }

  &-TrackButton-ArrowImage {
    image-rendering: pixelated;
    scale: 1;
    width: 64px;
  }

  &-Footer {
    grid-area: footer;
    margin-bottom: 1rem;
    text-align: center;

    @include with-breakpoint("phone-only") {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    @include with-breakpoint("tablet-portrait-up") {
      padding-left: max(1rem, calc(50% - var(--half-content-width)));
      padding-right: max(1rem, calc(50% - var(--half-content-width)));
    }
  }

  &-FooterLinkContainer {
    display: flex;
    gap: 2rem;
    justify-content: center;
  }

  &-FooterLink {
    color: var(--color-gray);
    display: flex;
    gap: 0.5rem;
  }

  &-FooterLinkLogo {
    height: auto;
    max-height: 1.5rem;
    max-width: 1.5rem;
    width: auto;
  }
}

@keyframes alpha-preview {
  0% {
    rotate: 10deg;
    scale: 1;
  }
  50% {
    rotate: 15deg;
    scale: 1.2;
  }
  100% {
    rotate: 10deg;
    scale: 1;
  }
}
