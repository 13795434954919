.OverworldMapExplorerPage {
  display: grid;
  grid: "nav" min-content "content" auto;

  &-Nav {
    grid-area: nav;
  }

  &-Content {
    grid-area: content;
  }

  &-Options {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
  }

  &-FeaturesOptions {
    display: flex;
    gap: 1rem;
  }

  &-FeatureOption {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 0.25rem;

    &:not(&_visible) {
      opacity: 0.5;
    }

    &_visible {
      opacity: 1;
    }
  }

  &-FeatureOptionImage {
    height: var(--text-icon-height);
    width: var(--text-icon-width);
  }

  &-MapContainer {
    aspect-ratio: var(--overworld-map-width-in-pixels) / var(--overworld-map-height-in-pixels);
    position: relative;
    width: 100%;
  }

  &-OverworldMapImage {
    display: block;
    height: 100%;
    width: 100%;
    z-index: 0;
  }

  &-FeatureImage {
    display: none;
  }

  &-Canvas {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}
