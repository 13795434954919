@import "../../assets/styles/_mixins";

.Nav {
  background-color: hsl(0, 0%, 10%);
  border-bottom: 1px solid hsl(0, 0%, 20%);
  display: flex;
  gap: 1rem;

  &-TitleLink {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    gap: 0.5rem;
  }

  &-OverworldMapExplorerImage {
    height: var(--text-icon-height);
  }
}
