@import "../../assets/styles/_mixins";

.FallbackPage {
  align-items: center;
  animation-duration: 250ms;
  animation-name: fade-in;
  animation-timing-function: ease-in;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  justify-content: center;

  &-Logo {
    image-rendering: pixelated;
    height: 128px;
    width: 128px;
  }

  &-Message {
    font-size: 2em;
    font-weight: 300;
    text-align: center;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
