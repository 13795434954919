@import "../../assets/styles/_mixins";

.NotFoundPage {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  min-height: 100vh;
  padding: 1rem;
  text-align: center;

  &-LinkDeathImage {
    image-rendering: pixelated;

    @include with-breakpoint("phone-only") {
      height: 72px;
      width: 64px;
    }

    @include with-breakpoint("tablet-portrait-up") {
      height: 144px;
      width: 128px;
    }
  }

  &-Message {
    @include with-breakpoint("phone-only") {
      font-size: 1.2em;
    }

    @include with-breakpoint("tablet-portrait-up") {
      font-size: 2em;
    }
  }

  &-ContinueLink {
    align-items: center;
    color: var(--color-gray);
    display: flex;
    gap: 0.5em;
  }

  &-HeartImage {
    display: inline-block;
    height: var(--text-icon-height);
    image-rendering: pixelated;
  }

  &-ContinueText {
    text-transform: uppercase;
  }
}
