.Button {
  border-radius: 3px;
  border-style: solid;
  border-width: 3px;
  cursor: pointer;

  &_green {
    background-color: hsl(120, 100%, 10%);
    border-color: hsl(120, 100%, 20%);
    color: var(--color-white);
  }

  &_white {
    background-color: var(--color-white);
    border-color: var(--color-gray);
    color: var(--color-black);
  }
}
